import { userService } from '../_services/user.service';
import router from '../_router';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

const actions = {
    login({ commit }, { username, password }) {

        userService.login(username, password)
            .then(
                response => {
                    // console.log("_store/auth/actions login()"response)
                    if ( response.status === 'SUCCESS' ) {
                        commit('loginSuccess', {
                            accessToken : response.access_token,
                            refreshToken : response.refresh_token
                        });
                        router.push('/');
                    }
                    else {
                        alert("帳號或密碼錯誤")
                    }
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
        router.push('login');
    },
    async refresh({ commit }, refreshToken) {
        const accessToken = await userService.refresh(refreshToken);
        // console.log("auth action accessToken", accessToken);
        commit('refresh', accessToken);
    }
};

const mutations = {
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    // loginFailure(state) {
    //     state.status = {};
    //     state.user = null;
    // },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    refresh(state, accessToken) {
        // console.log("auth mutations accessToken", accessToken)
        state.status.loggedIn = true;
        state.user = { ...state.user, accessToken: accessToken };
        // console.log("auth mutations state.user", state.user);
    }
};

export const auth = {
    namespaced: true,
    state,
    actions,
    mutations
};