function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'account' : username, 'password' :password })
    };
    return fetch(`${process.env.VUE_APP_API_URL}/api/auth/login`, requestOptions)
        .then( response=>response.json() )
        .then( data => {
            localStorage.setItem('user', JSON.stringify({
                accessToken : data.access_token,
                refreshToken: data.refresh_token
            }));     
            // console.log("user.service login",data.msg)
            return data;
        }).catch(e => {
            return e;
        });
}

function logout() {
    localStorage.removeItem('user');
}

/**
 * 
 * @param {*} refreshToken 
 * @returns access_token
 */
function refresh(refreshToken) {
    if(!refreshToken){
        throw new Error("refreshToken is not exist")
    }
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`
        },
    };
    return fetch(`${process.env.VUE_APP_API_URL}/api/auth/tokenrefresh`, requestOptions)
        .then( response=>response.json() )
        .then( data => {
            let user = JSON.parse(localStorage.getItem('user'))
            user = { ...user, accessToken: data.access_token }
            localStorage.setItem('user', JSON.stringify(user));
            // console.log("user.service New accessToken", data.access_token)
            return data.access_token;
        }).catch(e => {
            return e;
        });
}

export const userService = {
    login,
    logout,
    refresh
};
