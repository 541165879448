<template>
  <div class="z-40 fixed w-full header header-shadow">
    <router-link to="/">
      <img class="header_logo" src="../assets/header-logo.webp" alt="Akousist-header-logo">
    </router-link>
    
    <div class="block">
      <!-- <span>LoggedIn: {{isLoggedIn}}</span>
      <div class="w-40 truncate">accessToken: {{ accessToken }}</div>
      <div class="w-40 truncate">refreshToken: {{ refreshToken }}</div> -->
      <button v-if="isLoggedIn"
              @click="logout"
              class="neuButton px-6 py-1">logout</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Header',
  computed: {
      ...mapState({
          isLoggedIn: state => state.auth.status?.loggedIn,
          accessToken: state => state.auth.user?.accessToken,
          refreshToken: state => state.auth.user?.refreshToken
      })
  },
  methods: {
      ...mapActions ({
          logout: 'auth/logout'
      })   
  },
};
</script>

<style scoped>
.header {
    background-color: var(--my--gray-1);
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header a {
  font-weight: bold;
  color: #2c3e50;
}

.header a.router-link-exact-active {
  color: #4F8FFC;
}

.header-shadow {
--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.header_logo {
  height: 38px;
  margin: 6px 0px;
}


</style>