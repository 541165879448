import { createRouter, createWebHistory } from 'vue-router';
import store from '../_store';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    name: 'case01',
    component: () => import('../case01/index.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'Page404',
    component: () => import('../case01/index.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.status?.loggedIn
  if (to.name !== 'Login' && !isAuthenticated ) next("login");
  else if (to.name == 'Login' && isAuthenticated ) next("/");
  else next();
});

export default router;
